.root {
    display: flex;
    flex:1;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #e5e5e5;
    align-self: stretch;
}

.container {
  display: flex;
  width: 500px;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  align-items: center;
  /* align-self: stretch; */
}

.title {
  text-align: center;
  color: #100045;
  padding-top: 20px;
}

.mfaContainer {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
}

.phoneContainer {
    display: flex;
    width: 320px;
    flex: 1;
    margin: 20px 0px 10px 0px;
    justify-content: center;
}

.phoneInput {
  margin-left: 10px;
  padding-left: 5px;
  font-family: "Moderat-Bold";
  font-size: 12pt;
  border-radius: 5px;
  border-width: 1px;
  height: 36px;
  border-color: #e5e5e5;
  flex:1;
}

.otpContainer {
  display: flex;
  flex-direction: column;
  width: 320px;
  flex: 1;
  margin: 20px 0px 20px 0px;
  justify-content: center;
}

.otpInput {

  font-family: "Moderat-Bold";
  padding-left: 10px;
  font-size: 12pt;
  border-radius: 5px;
  border-width: 1px;
  height: 36px;
  border-color: #e5e5e5;
  height: 36px;
  margin-bottom: 10px;
}

.mfaContainer button {
  height: 40px;
  width: 320px;
}

.description {
  /* text-align: center; */
  margin-bottom: 10px;
  width: 375px;
}

.description2 {
  text-align: center;
  margin-bottom: 10px;
}

/***************** Hide Arrows In Input (Number) ****************/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.message{
  color:gray;
  font-style: italic;
  font-size: 14px;;
  margin-bottom: 10px;
 
}
.container {
    display: flex;
    flex-direction: column;
    background-color: #28008C12;
    width: 400px;
    height: auto;
    border-radius: 10px;
    padding: 20px;
    word-break: break-all;
}

.title {
    font-family: "Moderat-Bold";
    font-size: 22pt;
    color: #100045;
}

.message {
    margin-top: 20px;
    font-family: "Moderat-Regular";
    font-size: 12pt;
    color: #100045;
    word-break: break-word;
    text-align: justify;
}
.inputmessage {
    margin-top: 20px;
}

.buttonsContainer {
    display: flex;
    /* background-colsor: aqua; */
    margin-top: 20px;
}

.button {
    display: flex;
    flex: 1;
    /* width: 200px; */
    height: 40px;
    background-color: #100045;
    color: #fff;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 8px 8px;
    cursor: pointer;
}

.delete {
    background-color: #100045;
}

.cancel {
    background-color: #A0A1A4;
}

.info {
    background-color: #005EA5;
}

.success {
    background-color: #009900;
}

.warning {
    background-color: #EB9700;
}

.default {
    background-color: #fff;
    color: #100045;
    border-width: 1px;
    border-color: #100045;
    border-style: solid;
    height: 38px;
}

.spinner{
    display: flex;
    flex : 1;
    height: 40px;
    /* background-color: red; */
    justify-content: center;
    align-items: center;
    margin: 8px 8px;
}
.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 40px;
  padding-left: 120px;
  padding-right: 50px;
  padding-bottom: 40px;
}

.pageTitle {
  color: #100045;
  font-family: "Moderat-Bold";
}

.sectionTitle {
  color: #100045;
  font-family: "Moderat Bold";
}

.fieldContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 20px;
}
.input{
  max-width: 500px;
  margin-bottom: 0px;
}

.inputError,
.selectError {
  border-color: #CD3500;
}

.passwordBtn {
  width: 250px;
  display: flex;
}

.container button {
  margin-top: 20px;
}

.submit {
  align-self: center;
}

.submit:disabled {
  opacity: 50%;
  filter: grayscale(1);
  cursor: not-allowed;
}

.tooltipStyle {
  margin-left: 8px;
  margin-top: 2px;
}

.displayFlex {
  display: flex;
}
.roleContainer{
  display: flex;
  margin-bottom: 20px;
  color: #717476;
  font-family: Moderat-Regular;
}

.functionalRolesContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-right: 6px;
  border-right: 2px solid #E5E5E5;
  width: 230px;

}

.permissionsContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  flex: 2;
  margin-left: 16px;
  justify-content: space-around;
  align-items: self-start;
  height: 0px;
}

.permissionCol {
  display: flex;
  flex-direction: column;
}

.permission {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  color: #717476;
  font-family: Moderat-Regular;
}
.container input[type="checkbox"] {
  width: 16px;
  height: 16px;
}
.container input[type="checkbox"]:disabled {
  cursor: not-allowed;
}
.numberContainer{
  display: flex;
  max-width: 500px;
}
.phoneContainer {
  display: flex;
  flex: 2;
  flex-direction: column;
  margin-bottom: 20px;
}

.checkbox {
  width: 1rem;
  height: 1rem;
}

.checkbox__container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.checkbox__label {
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-bottom: 8px;
}

.review_checkbox__label{
  font-size: 0.875rem;
  font-weight: 600;
}

.fluidInput {
  width: calc(100% - 1rem);
  max-width: 100%;
  margin-top: 0.25rem;
  border: 1px solid #28008c30;
  margin-left: 0.5rem;
  padding: 5px;
  border-radius: 5px;
}
.fluidInputs {
  width: calc(100% - 1rem);
  max-width: 100%;
  margin-top: 0.25rem;
  /* border: 1px solid #28008c30; */
  margin-left: 0.5rem;
  padding: 5px;
}

.container {
  border: 0;
  padding-inline: 0;
  margin-inline: 0;
  padding-block: 0;
  margin-block: 0;
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
}

.radio__container {
  /* display: flex; */
  align-items: center;
  gap: 2rem;
  padding: 0.25rem 0;
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer {
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: space-between;
  padding-top: 1rem;
  border-top: 1px solid #ccc;
}

.footer button {
  margin-bottom: 0 !important;
}

.filterItem {
  list-style-type: none;
}

.filterList {
  padding-inline: 0;
}

.hLine {
  display: flex;
  align-items: center;
}

.hLIne * {
  flex-shrink: 0;
}

.noPadding {
  padding-inline: 0;
}

.filterContainer {
  padding: 8px 12px;
  border: 1px solid #28008c30;
  margin: 12px 0px 0px 8px;
  border-radius: 8px;
}

.gap {
  gap: 1rem;
}

.fluidInput > div {
  width: 100% !important;
}
.dropDown {
}

.dateInputButton {
  display: flex;
  align-items: center;
  background-color: #efefef;
  height: 42px;
  flex-grow: 0;
  width: 100%;
  border-radius: 5px;
  border-width: 1px;
  border-color: #c5c1c8;
  border-style: solid;
  color: #100045;
}

.calendarIcon {
  height: 26px;
  width: 26px;
  margin-right: 10px;
}
.closeButton {
  display: flex;
  background-color: #fff;
  color: #100045;
  border: 2px solid #100045 !important;
  border-radius: 5px;
  height: 40px;
  border-width: 0px;
  width: 100px;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 14pt;
  font-family: "Moderat-Regular";
  cursor: pointer;
}
.applyButton {
  display: flex;
  background-color: #100045;
  color: #fff;
  border-radius: 5px;
  height: 40px;
  border-width: 0px;
  width: 100px;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 14pt;
  font-family: "Moderat-Regular";
  cursor: pointer;
}

.applyButton:disabled {
  background-color: gray;
}

.srOnly {
  opacity: 0;
  position: fixed;
  left: -110vw;
  top: -110vh;
  transform: translate3d(-100%, -100%, -100%);
}

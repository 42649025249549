.container {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    /* background-color: salmon; */
    flex: 1;
    padding-top: 40px;
    padding-left: 120px;
    padding-right: 80px;
    position: relative;
}

.pageTitle {
    color: #100045;
    font-family: "Moderat-Bold";
    font-size: 16px;;
}
.articleimage{
    color: #100045;
    font-family: "Moderat-Bold";
    font-size: 16px;;
    margin-bottom: 10px;
}

.description{
    color: #100045;
    font-family: "Moderat-Bold";
    margin-top: 10px;
    font-size: 16px;;
}

.editor {
    display:flex;
    flex-direction: column;
    height:600px;
    border: 1px solid #ccc;
    margin: 15px 0;
    border-radius: 5px;
    padding:10px;
}


h3[data-block=true] > div span{        /* data attribute*/
    color:rgb(16, 0, 69);
}

.editorTextBox{
    /* height: 600px; */
    /* height:400px; */
    flex-grow: 1;
    height: auto !important;
    /* width:0 400px; */
    /* width : 0 500px; */
    
}
.toolbar{
    flex-grow: 0;
}

.addSectionBtn {
    margin: 20px 0;
    display: flex;
    justify-content: flex-end;
}

/* .back{
    margin-bottom: 0;
    text-decoration:none;
    position:absolute;
    right:0;
    top:0;
    margin-top: 0;
    width:auto !important;
    padding: 0 12px;
    background-color: white;
    color:#100045;
    border: 1px solid #100045;
    height: 40px;
    width: 300px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
} */
.backButton{
    width:16px;
    height: 16px;
    /* background-color: white; */
    color: #100045;
    filter:invert(1);
    transform:scaleX(-1);
    margin-right: 6px;
    
}
.submit {
    align-self: center;
    border-radius: 5px;
}

.submit:disabled{
    color:black;
    background:grey;
    cursor:not-allowed;
}

.submitCenter{
    display: flex;
    justify-content: center;
}
.imageUploader{
    width:600px;
}
.switchContainer{
    display:flex;
    /* align-items: center; */
    /* justify-content: flex-end; */
    margin-bottom: 22px;
    /* margin-right: 20px;; */
    margin-top: 20px;
    /* margin-left: 114px; */
    justify-content: space-between;
    }
    .switchContainer p{
        flex-grow:1;
        color:#100045;
        
    }
.readingtime{
    color:#100045; 
  font-family: bold;

}

.noteContainer {
    margin-top: 15px;
    color: #717476;
    font-style: italic;
}
.input{
    margin-bottom: 0px;
    max-width: 600px;
}
/* dont delete need for styling later on */
/* .container {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    flex: 1;
    padding-top: 40px;
    padding-left: 120px;
    padding-right: 80px;
    position: relative;
} */
.fieldContainer{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.script{
    color : red;
    margin-bottom: 4px;
    font-size: 14px;
    font-style: italic;
}
.submitBack {
    display: flex;
    background-color: #100045;
    color: #fff;
    border-radius: 5px;
    height: 40px;
    border-width: 0px;
    width: 90px;
    align-self: center;
    justify-content: center;
    align-items: center;
    font-size: 14pt;
    font-family: "Moderat-Regular";
    cursor: pointer;
    text-decoration: none;
    align-self: self-end;
}
.formInput {
    background-color: #efefef;
    height: 40px;
    /* max-width: 500px; */
    border-radius: 5px;
    border-width: 1px;
    margin-top: 5px;
    gap:5px;
    border-color: #C5C1C8;
    border-style: solid;
    padding-left: 10px;
    color: #100045;
    font-family: "Moderat-Regular";
    -webkit-box-sizing: border-box; /* prevent additonal 6px in width /height */
    -moz-box-sizing: border-box; /* prevent additonal 6px in width /height */
    box-sizing: border-box; /* prevent additonal 6px in width /height */
}

.textArea {
    background-color: #efefef;
    min-height: 120px;
    max-width: 500px;
    min-width: 500px;
    border-radius: 5px;
    border-width: 1px;
    border-color: #C5C1C8;
    border-style: solid;
    padding-top: 10px;
    padding-left: 10px;
    font-family: "Moderat-Regular";
    color: #100045;
    -webkit-box-sizing: border-box; /* prevent additonal 6px in width /height */
    -moz-box-sizing: border-box; /* prevent additonal 6px in width /height */
    box-sizing: border-box; /* prevent additonal 6px in width /height */
}

.popper {
    z-index: 1000;
}

.calendarIcon {
    height: 26px;
    width: 26px;
    margin-right: 10px;
}

.dateInputButton {
    font-family: 'Moderat-Regular';
    display: flex;
    align-items: center;
    background-color: #efefef;
    height: 42px;
    width: 160px;
    border-radius: 5px;
    border-width: 1px;
    border-color: #C5C1C8;
    border-style: solid;
    color: #100045;
    margin-right:40px;
    margin-bottom: 20px;
}

.datePickerContainer{
    position: absolute;
    left: 800px;
    top: 243px;
    margin-left: 20px;
}

.dateContainer{
    display: flex;
}
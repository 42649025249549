.button{
    display: flex;
    align-self: flex-end;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100px;
    border-radius: 20px;
    border-width: 0px;
    color: #fff;
    font-size: 12pt;
    font-family: Moderat-Regular;
    text-decoration-line: none;
    margin-bottom: 5px;
    margin-right: 5px;
    cursor: pointer;
    
}
.publish{
    background-color: #28008C;

}
.unpublish{
    background-color:  #d31717;
}


.button:hover{
    background-color: grey;
    /* background-color: #d1ade9; */
}
.total{
    width:fit-content;
    /* background-color: #100045; */
    /* border: 3px solid #100045 !important; */
    color: #100045;
    font-family: Moderat-Regular;
    border-radius: 5px;
    border-width: 0;
    padding: 5px;
    margin-left: 40px;
    font-size: 18px;
    text-decoration-line: none;
    font-weight: bold;
    align-self: center;
}
.divFlex{
    display:flex;
    justify-content: space-between;
    }
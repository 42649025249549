.container {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  padding: 30px;
}
.addUserButton {
  display: flex;
  align-self: flex-end;
  justify-content: center;
  align-items: center;
  background-color: #28008c;
  height: 40px;
  width: 200px;
  border-radius: 20px;
  border-width: 0px;
  color: #fff;
  font-size: 12pt;
  font-family: Moderat-Regular;
  text-decoration-line: none;
}
.listContainer {
  display: flex;
  align-self: stretch;
  padding: 10px;
  margin-top: 4px;
  margin-top: 16px;
}
.userList {
  display: flex;
  flex: 1;
  flex-direction: column;
  list-style: none;
  justify-content: center;
}

.userContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  border-radius: 7px;
  border-color: #28008c30;
  border-width: 2 px;
  border-style: solid;
  padding: 8px;
  align-items: center;
}
.detailsContainer {
  display: flex;
  flex-direction: column;
  flex: 2;
  margin-left: 30px;
  margin-right: 10px;
  align-self: stretch;
  /*justify-content: space-between;*/
}
.emailID {
  color: #717476;
  font-family: Moderat-Regular;
  margin: 10px 0px 10px 0px; 
  overflow: hidden;
  text-overflow: ellipsis;
}
.actionContainer {
  display: flex;
  width: 290px;
  justify-content: flex-end;
  align-items: center;
}
.edit {
  display: flex;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}
.delete {
  display: flex;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}
.chevronContainer {
  display: flex;
  align-self: center;
}

.chevron {
  height: 30px;
  width: 30px;
}
.title {
  color: #100045;
  font-family: Moderat-Bold;
  font-size: 14pt;
}
.roleDetailsLabel{
    color: #5D30A6;
    font-family: Moderat-Regular;
    margin: 0px 0px 10px 0px; 
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 6px;
  }
  .roleDetailsValue{
    color: #717476;
    font-family: Moderat-Regular;
    margin: 0px 0px 10px 0px; 
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .roleDetailsContainer{
    display: flex;
  }
  .roleDetailsField{
    margin-right: 16px;
    display: flex;
  }
  .infoIconStyle{
    width: 20px;
    height: 20px;
  }
  .additionalRolesInfo{
    color: #5D30A6;
    font-family: Moderat-Regular;
    margin-left:8px ;
  }
  .infiniteScrollContainer{
    width: 100%;
  }
  .total{
    width:fit-content;

    color: #100045;
    font-family: Moderat-Regular;
    border-radius: 5px;
    border-width: 0;
    padding: 5px;
    font-size: 18px;
    text-decoration-line: none;
    font-weight: bold;
    margin-left:05px;
    
}
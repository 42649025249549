.container {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;
    flex: 0.3;
}

.separator {
    background-color: #A0A1A4;
    height: 2px;
    margin-right: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.subReportContainer {
    display: flex;
    flex-direction: column;
    min-width: 350px;
    max-width: 350px;
    min-height: 350px;
    max-height: 400px;
    flex-grow: 1;
    border-radius: 10px;
    border-color: #28008C12;
    border-width: 2 px;
    border-style: solid;
    /*background-color: salmon;*/
    /* margin-left: 10px; */
    margin-right: 20px;
    margin-bottom: 20px;
    

    /** commenting out as per inputs from Potato */
    /* box-shadow: -7px 8px 19px -5px rgba(16,0,65,0.46);
    -webkit-box-shadow: -7px 8px 19px -5px rgba(16,0,65,0.46);
    -moz-box-shadow: -7px 8px 19px -5px rgba(16,0,65,0.46); */
}

.primary {
    color: red
}

.reportSectionContainer {
    display: flex;
    /* background-color: aqua; */
    flex : 1;
    flex-direction: column;
}
.loaderContainer{
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;
    flex: 1;
}

.calendarIcon {
    height: 26px;
    width: 26px;
    margin-right: 10px;
}


.datePickerContainer{
    position: absolute;
    left: 800px;
    top: 243px;
    margin-left: 20px;
}

.dateContainer{
    display: flex;
}

.buttonContainer{
  margin: auto;
  width: 50%;
}

.tabSelected{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #100045;
    margin: 0px 10px 0px 0px;
    width: 120px;
    height: 40px;
    border-radius: 22px;
    border-color: #100045;
    border-style: solid;
    border-width: 2px;
    color: #ffffff;
    cursor: pointer; 
    letter-spacing: 1.5px;  
    font-size: 12pt;
}

.label {
    font-family: "Moderat-Bold";
    margin-bottom: 10px;
    color: #100045;
}

.vContainer{
    display: flex;
    flex-direction: column;
}

.dateInputButton {
    font-family: 'Moderat-Regular';
    display: flex;
    align-items: center;
    background-color: #efefef;
    height: 42px;
    width: 160px;
    border-radius: 5px;
    border-width: 1px;
    border-color: #C5C1C8;
    border-style: solid;
    color: #100045;
    margin-right:40px;
    margin-bottom: 20px;
}
.disabled{
    display: flex;
    align-self: flex-end;
    justify-content: center;
    align-items: center;
    background-color:grey;
    height: 40px;
    width: 120px;
    border-radius: 20px;
    border-width: 0px;
    color: #fff;
    font-size: 12pt;
    font-family: 'Moderat-Regular';
    text-decoration-line: none;
    cursor:auto;
    letter-spacing: 1.5px;  
}

.container{
    display: flex;
    flex-direction: column;
}

.imageUploader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: dashed 2px seagreen;
    height: 120px;
    /* width: 510px; */
    background-color: rgb(215, 251, 239);
    border-radius: 5px;
    font-family: "Moderat-Regular";
    color: #100045;
}

.fileDisplayContainer {
    /* position: fixed; */
    /* width: 805px; */
    /* background-color: red; */
    /* height: 100px; */
}

.fileStatusBar{
    display: flex;
    width: 100%;
    vertical-align:top;
    margin-top: 10px;
    margin-bottom: 20px;
    position: relative;
    line-height: 40px;
    height: 40px;
}

.fileStatusBar > div {
    overflow: hidden;
}

.fileType {
    display: inline-block!important;
    position: absolute;
    font-size: 8px;
    font-weight: 700;
    line-height: 13px;
    margin-top: 15px;
    padding: 0 4px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px #abc;
    color: #fff;
    background: #0080c8;
    text-transform: uppercase;
}

.fileName {
    display: inline-block;
    vertical-align:top;
    margin-left: 50px;
    color: #4aa1f3;
}

.fileError {
    display: inline-block;
    vertical-align: top;
    margin-left: 50px;
    color: #9aa9bb;
}

.fileErrorMessage {
    color: red;
}

.fileTypeLogo {
    width: 30px;
    height: 30px;
    background: url(../../assets/images/file.png) no-repeat center center; 
    background-size: 100%;
    position: absolute;
}

.fileSize {
    display:inline-block;
    vertical-align:top;
    color:#30693D;
    margin-left:10px;
    margin-right:5px;
    margin-left: 10px;
    color: #444242;
    font-weight: 700;
    font-size: 14px;
}

.fileRemove  {
    position: absolute;
    top: 20px;
    right: 10px;
    line-height: 15px;
    cursor: pointer;
    color: red;
    margin-right: -10px;
}

.fileUploadBtn {
    color: white;
    text-transform: uppercase;
    outline: none;
    background-color: #100045;
    font-weight: bold;
    padding: 8px 15px;
    margin-bottom: 5px;
    margin-top: 10px;
    width: 160px;
    border-width: 0px;
    border-radius: 3px;
    cursor: pointer;
    align-self: center;
}

.fileUploadBtnDisabled {
    background-color:#abc;
}

.noteContainer {
    margin-top: 10px;
    color: #717476;
    font-style: italic;
}
.noteContainer span {
    font-weight: bold;
}

.tailspin{
    align-self: center;
    margin: 20px;
    /* background-color: red; */
}

.section{display: flex;
    /* background-color: blanchedalmond; */
    height: 200px;
    width: 200px;
    border-radius: 10px;
    align-self: center;
    justify-content: center;


}
.bodyContent{
    font-family: "Moderat-Regular";
    color: #100045;
    border: 1px solid #E5E5E5;
    padding: 8px 8px;
    width: 200px;
    font-size: 10pt;
    background-color: #E5E5E5;
    border-radius: 5px;
}
.IconConatiner{
    width: 20px;
}
.iconStyle{
    width: 16px;
    height: 16px;
    border-radius: 16px;
}
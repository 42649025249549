.filterbox {
  position: absolute;
  right: 40px;
  z-index: 1;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-top: 3px;
  width: 344px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.headerContainer {
  display: flex;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 16px;
}

.header {
  flex: 1;
  font-size: 16px;
  height: 48px;
  line-height: 48px;
}
.close {
  align-content: flex-end;
  align-self: center;
  cursor: pointer;
}
.active{
  background-color:#100041 !important;
  color: #fff;
}

.applybtn {
  background: #ffffff;
  border: 2px solid #28008c30;
  padding-left: 10px;
  border-radius: 6px;
  cursor: pointer;
  height: 42px;
  width: 150px;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterIcon {
  width: 16px;
  height: 16px;
  margin-left: 10px;
  align-items: center;
}


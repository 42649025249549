.container {
    width: 100%;
    height: 100px;
    background-color: red;
    position: sticky;
    top: 0;
    z-index: 999;
}

.headerWrapper {
    height: 100%;
    background-color: #FFE6E6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px;
}

.logoContainer {
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.title {
    color: #28008C;
    margin-left: 10px;
}

.logo {
    height: 50px;
}

.rightNavMenu { 
    display: flex;
    flex-direction: row;
    padding: 10px;
}

.popup {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    background-color: rgba(62, 66, 67);
    color: rgb(233, 233, 233);
    border-radius: 5px;
    padding: 10px;
}

.rightNavMenuItem {
    width: 50px;
    height: 50px;
    background-color: lightgrey;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 5px;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: lightgrey;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16pt;
    color: white;
    font-weight: 700;
    align-self: center;
    margin-right: 10px;
}
.logOutBtn{
    width: 50px;
    height: 50px;
    background: url("../../assets/images/logout.png") no-repeat;
    background-size: 40px 40px;
    position: relative;
    top: 5px;
    left: 3px;
}
.logOutBtn:hover{
    cursor: pointer;
}
.timeOutPanel{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-shadow: 0 0 5px 2px #282a2d;
    padding: 20px;
    margin-top: 100px;
    margin-right: 20px;
    border-radius: 10px;
    background-color: rgb(245, 35, 35);
    color: #FFE6E6;
}


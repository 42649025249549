.listContainer {
    display: flex;
    flex: 1;
    align-self: stretch;
    /* padding: 10px; */
    /* margin-top: 4px; */
}

.incidentList {
    display: flex;
    flex: 1;
    flex-direction: column;
    list-style: none;
}

.title {
    color: #100045;
    font-family: Moderat-Bold;
    font-size: 14pt;
    cursor: pointer;
}
.titleValue {
    color: #717476;
    font-family: Moderat-Bold;
    font-size: 14pt;
    cursor: pointer;
}

.subtitle {
    color: #717476;
    font-family: Moderat-Regular;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 20px;
    max-height: 40px;
    /* fallback */
    -webkit-line-clamp: 2;
    /* number of lines to show */
    -webkit-box-orient: vertical;
}

.label {
    color: #5D30A6;
    font-family: Moderat-Regular;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 6px;
}

.expireInLabel {
    color: #5D30A6;
    font-family: Moderat-Regular;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 6px;
    margin-bottom: 10px;
}

.value {
    color: #717476;
    font-family: Moderat-Regular;
    overflow: hidden;
    text-overflow: ellipsis;
}

.labelValueField {
    margin-right: 16px;
    display: flex;
}
.expiresInContainer {
    display: flex;
    flex-direction: column;
    width: 120px;
    margin-left: 10px;
    margin-right: 10px;
    align-self: stretch;
}
.trangleBadge{
    width: 0;
    height: 0;
    border-right: 50px solid transparent;
    position: relative;
    display: flex;
    align-self: stretch;
}
.expired{
    color: #CD3500;
    font-family: Moderat-Regular;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 6px;
    margin-bottom: 10px;
}
.pending {
    color: #CD3500
}
 
.message{
    margin-left: 42px;
    font-style: italic;
    color:gray;
}
.divmain{
    display:flex;
    margin-left: 50px;
    font-style: bold;
    justify-content: space-between;
    margin-top: 3px;
}

.div11{
background-color:rgb(230, 0, 0);
width:15px;
height:15px;
margin-top: 4px;
border-radius: 2px;
margin-left: 8px;
}

.div22{
background-color:rgb(235, 151, 0);
width:15px;
height:15px;
margin-top: 4px;
border-radius: 2px;
margin-left: 8px;
}

.div33{
background-color:rgb(0, 94, 165);
width:15px;
height:15px;
margin-top: 4px;
border-radius: 2px;
margin-left: 8px;
}
.total{
    width:fit-content;
    /* background-color: #100045; */
    /* border: 3px solid #100045 !important; */
    color: #100045;
    font-family: Moderat-Regular;
    border-radius: 5px;
    border-width: 0;
    padding: 5px;
    margin-left: -14px;
    font-size: 18px;
    text-decoration-line: none;
    font-weight: bold;
    align-self: center;
}

.main{
display:flex;
margin-left: 32px;
}
.div1{
margin-left: 5px;
}
.div2{
    margin-left: 5px;
    }
    .div3{
        margin-left: 5px;
        }

        .noDataContainer {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
        
        .emptyLabel {
            color: #100045;
            font-family: Moderat-Regular;
            font-size: 18pt;
            margin-top: 80px;
        }
        
        .emptyIcon {
            width: 100px;
            height: 100px;
            margin-top: 30px;
        }
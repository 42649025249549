.container {
    display: flex;
    height: 60px;
    flex: 1;
    /* background-color: palevioletred; */
    align-items: center;
    margin-bottom: 50px;
}


.dropDownTitle {
    color: lightslategray;
    font-family: "Moderat-Regular";
    margin-left: 4px;
    margin-bottom: 4px;
}

.dropDownContainer {
    display: flex;
    flex-direction: column;    
    padding: 4px;
    /* background-color: #10f434; */
    color: "#fff";
    margin-right: 4px;
    margin-top: 4px;
}

.options{
    font-size: 14px;
    padding: 5px;
    background: #5c5c5c;
}

.menuList {
    /* background-color: blue; */
    display: flex;
    flex-direction: column;
    padding: 4px;
}

.applyButton {
    background-color:#100041;
    height: 40px;
    border-radius: 3px;
    border-width: 0px;
    color: white;
}

.applyButton:hover{
    cursor: pointer;
}

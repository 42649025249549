.App {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
}

.content {
  display: flex;
  flex: 1;
  background-color: white;
}

.pages {
  display: flex;
  flex: 1;
  background-color: rgb(255, 255, 255);
}

.truncate {
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  max-width:380px;
}

.eventList {
    display: flex;
    flex: 1;
    flex-direction: column;
    list-style: none;
    justify-content: center;
}

.addEventButton {
    display: flex;
    align-self: flex-end;
    justify-content: center;
    align-items: center;
    background-color: #28008C;
    height: 40px;
    width: 200px;
    border-radius: 20px;
    border-width: 0px;
    color: #fff;
    font-size: 12pt;
    font-family: Moderat-Regular;
    text-decoration-line: none;
}

.listContainer {
    display: flex;
    flex: 1;
    /* background-color: brown; */
    align-self: stretch;
    /* padding: 10px; */
    margin-top: 4px;
}

.eventName {
    /* color: #2d2e2f; */
    color: #1F006B;
    font-family: Moderat-Regular;
    font-weight: 900;
}

.eventTime {
    color: #717476;
    font-family: Moderat-Regular;
}

.location {
    display: flex;
    align-items: center;
    color: #717476;
    font-family: Moderat-Regular;
    /* background-color: red; */
    
}
.pin {
    height: 20px;
    margin-right: 5px;
    /* background-color: navy; */
}

.camera {
    height: 15px;
    margin-right: 5px;
    /* background-color: navy; */
}

.pending {
    color: #CD3500
}

.subReportContainer {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    min-height: 250px;
    max-height: 400px;
    flex-grow: 1;
    border-radius: 10px;
    border-color: #28008C12;
    border-width: 2 px;
    border-style: solid;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-left: 50px;
}
.recurringData{
    /* margin-top: 15px; */
    color: #585a5b;
    font-style: bold;
    /* font-style: italic; */
}
.total{
    width:fit-content;
    /* background-color: #100045; */
    /* border: 3px solid #100045 !important; */
    color: #100045;
    font-family: Moderat-Regular;
    border-radius: 5px;
    border-width: 0;
    padding: 5px;
    margin-left: 40px;
    font-size: 18px;
    text-decoration-line: none;
    font-weight: bold;
    align-self: center;
}
.divFlex{
display:flex;
justify-content: space-between;
}
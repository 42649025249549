.container {
    display: flex;
    flex:1;
    
    /* background-color: red; */
    height: auto; 
    min-height: calc(100vh - 120px);
}

.content {
    display: flex;
    flex:1;
    width: 100%;
}

.sidebarWrapper {
    /* width: 250px; */
    /* background-color: lime; */
    background-color: #E5E5E5;
    position: sticky;
    top: 100px;
    height: 100vh;
    padding: 10px;
    box-shadow: 9px 0px 39px -12px rgba(0,0,0,0.51);
    -webkit-box-shadow: 9px 0px 39px -12px rgba(0,0,0,0.51);
    -moz-box-shadow: 9px 0px 39px -12px rgba(0,0,0,0.51);
}

.title {
   color: #ffffff; 
}

.sidebarMenu{
    margin-bottom: 10px;
}

.sidebarList{
    list-style: none;
    padding: 10px;
}

.itemLink {
    text-decoration-line: none;
}

.menuItem {
    width: 200px;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0px;
    border-radius: 30px;
    background-color: #fff;
    margin-bottom: 10px;
    color: #100041;
    font-weight: 700;
    text-align: left;
    border: solid 1px #100041;
}

.menuItemContainer{
    margin: 20px;
    display: flex;
    height: 100%;
    align-items: center;
}

.menuIcon {
    width: 30px;
    height: 30px;
    margin-right: 10px;   
}

.menuTitle {
    font-weight: 900;
    font-size: 14pt;
    font-family: 'Moderat-Bold';
}

.activeItem{
    background-color: #100041;
    color: #ffffff;
}

.menuItem:hover{
    background-color: #100041;
    color: #ffffff;
}
.menuItem:hover img{
    filter: brightness(0) invert(1);
}
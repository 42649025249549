.container {
    display: flex;
    flex-direction: row;
    padding: 10px 0px 20px 0px;
}

.tab {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    margin: 0px 10px 0px 0px;
    width: 120px;
    height: 40px;
    border-radius: 22px;
    border-color: #100045;
    border-style: solid;
    border-width: 2px;
    color: #100045;
    cursor: pointer;

}

.tabSelected {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #100045;
    margin: 0px 10px 0px 0px;
    width: 120px;
    height: 40px;
    border-radius: 22px;
    border-color: #100045;
    border-style: solid;
    border-width: 2px;
    color: #ffffff;;
    cursor: pointer;
}
.label {
    font-family: "Moderat-Bold";
    margin-bottom: 8px;
    color: #100045;
}
.value {
    font-family: "Moderat-Regular";
    margin-bottom: 10px;
    color: #717476;
    margin-left: 16px;
    /* text-transform: capitalize; */
}

.error {
    color: #CD3500;
    /* font-family: "Moderat-Regular"; */
    font-size: 14px;
}
.label2{
    color:red;
    font-family: "Moderate-Bold";
    margin-bottom: 10px;
    padding-left: 1px;
}
.label3 {
    font-family: "Moderat-Bold";
    color: #100045;
    margin-bottom: 10px;
}
.label4{
    color:red;
    font-family: "Moderate-Bold";
    padding-left: 1px;
    margin-bottom: -10px;
}
.label5 {
    font-family: "Moderat-Bold";
    color: #100045;
}
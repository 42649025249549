.title {
    color: #100045;
    font-family: Moderat-Bold;
    font-size: 14pt;
    cursor: pointer;
}
.titleValue {
    color: #717476;
    font-family: Moderat-Bold;
    font-size: 14pt;
    cursor: pointer;
}

.label {
    color: #5D30A6;
    font-family: Moderat-Regular;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 6px;
}

.value {
    color: #717476;
    font-family: Moderat-Regular;
    overflow: hidden;
    text-overflow: ellipsis;
}

.noDataContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.emptyLabel {
    color: #100045;
    font-family: Moderat-Regular;
    font-size: 18pt;
}

.emptyIcon {
    width: 100px;
    height: 100px;
    margin-top: 30px;
}
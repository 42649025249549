body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: red; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #837B8B;
  opacity: 1; /* Firefox */
  font-family: "Moderat-Regular";
  font-size: 10pt;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #837B8B;
  font-family: "Moderat-Regular";
  font-size: 10pt;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #837B8B;
  font-family: "Moderat-Regular";
  font-size: 10pt;
}

@font-face {
  font-family: "Moderat-Bold";
  src: local("Moderat-Bold"),
   url("./fonts/Moderat-Family-Web/Moderat-Black.woff") format("truetype");
  font-weight: bold;
}

@font-face {
    font-family: "Moderat-Light";
    src: local("Moderat-Light"),
     url("./fonts/Moderat-Family-Web/Moderat-Light.woff") format("truetype");
}

@font-face {
  font-family: "Moderat-Regular";
  src: local("Moderat-Regular"),
   url("./fonts/Moderat-Family-Web/Moderat-Regular.woff") format("truetype");
}

/*Sujit Common Code*/
.buttonPrimary {
  display: flex;
  background-color: #100045;
  color: #fff;
  border-radius: 5px;
  border-width: 0px;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 14pt;
  font-family: "Moderat-Regular";
  cursor: pointer;
  margin-bottom: 20px;
  box-sizing: border-box;
}
.buttonSecondary{
  display: flex;
  background-color: #FFF;
  border: 1px solid #100045 !important;
  color: #100045;
  border-radius: 5px;
  border-width: 0px;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 14pt;
  font-family: "Moderat-Regular";
  cursor: pointer;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.buttonPrimary:disabled{
  background-color: gray;
}
.inputLabel{
  font-family: "Moderat-Bold";
  margin-bottom: 10px;
  color: #100045;
}
.inputControl{
  background-color: #efefef;
  border-radius: 5px;
  border-width: 1px;
  border-color: #C5C1C8;
  border-style: solid;
  padding-left: 10px;
  color: #100045;
  font-family: "Moderat-Regular";
  box-sizing: border-box;
}
.checkboxLabel{
  font-family: "Moderat-Bold";
  margin-bottom: 10px;
  color: #100045;
  position: relative;
  top: -4px;
}
.checkboxControl{
  width:20px;
  height:20px;
  cursor: pointer;
}


.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
  padding-inline-start:20px !important;
}
.container {
  display: flex;
  flex-direction: column;
  /* height: 100px; */
  /* border-color: red;
  border-width: 2px;
  border-style: dashed;
  padding: 10px; */
  gap:10px;
}

.dummyField {
  display: flex;
  /* background-color: chartreuse; */
  /* height: 20px; */
  /* border-width: 1px;
    border-style: dotted;
    border-radius: 5px; */
}
.week {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin: 12px 0;
}
.day {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  font-size: 20px;
}

.day:has( [type="checkbox"]:checked){
    background: blue;
    color:white

}

.day > [type="checkbox"] {
  display: none;
}
.month{
    margin: 12px 0;
}
.checkbox{
    display:flex;
    flex-direction: column;
}
.saveRule{
    padding: 12px 6px;
    background-color: #100045;
    color: white;
    width:fit-content;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 5px;;
}
.saveRule:disabled{
    opacity:0.5;
    cursor: not-allowed;
}
/* .input{
    margin-left: 5px;
    margin-top: 5px
} */

.noteContainer {
  margin-top: 15px;
  color: #717476;
  font-style: italic;
}

.repeatSwitch{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:15px;
  margin-bottom:10px;
  margin-top: 10px;
}
.texts{
  color:#100045;
  font-family: "Moderat-Light";
}
.weekred{
  color:red;
  margin-top: 8px;
}
.container {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    /* background-color: salmon; */
    flex: 1;
    padding-top: 40px;
    padding-left: 120px;
    padding-right: 80px;
    position: relative;
}
.submit:disabled{
    color:black;
    background:grey;
    cursor:not-allowed;
}


.pageTitle {
    color: #100045;
    font-family: "Moderat-Bold";
}

.fieldContainer{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.input{
    margin-bottom: 0px;
    max-width: 600px;
}

.inputError {
    border-color: #CD3500;
}

.submit {
    align-self: center;
}

.row{
    display: flex;
    align-items: center; 
    margin-top: 20px;
    /* background-color: #CD3500; */
}

.day{
    display: flex;
    margin-right: 30px;
    width: 170px;
}

.apSwitch {
    margin-right: 30px;
}

.header {
    display: flex;
    /* background-color: red; */
    margin-right: 30px;
    width: 170px;
    font-family: "Moderat-Bold";
}

.col1 {
    margin-right: 30px;
    width: 150px;
    /* background-color: yellow; */
}

.col2 {
    margin-right: 10px;
    width: 200px;
    /* background-color: aquamarine; */
    display: flex;
}

.col3 {
    margin-right: 30px;
    width: 70px;
    /* background-color: dodgerblue; */
}

.checkBox {
    margin-right: 16px;
    width: 20px;
    height: 20px;
    cursor: pointer; 
}

.error {
    color: #CD3500;
    font-family: "Moderat-Regular";
    font-size: 14px;
}

.back{
    margin-bottom: 0;
    text-decoration:none;
    position:absolute;
    right:0;
    top:0;
    margin-top: 0;
    width:auto !important;
    padding: 0 12px;
    /* background-color: white; */
    background-color: #100045;
    /* color:#100045; */
    color:white;
    border: 1px solid #100045;
    height: 40px;
    width: 300px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.backButton{
    width:16px;
    height: 16px;
    /* background-color: white; */
    background-image: none;
    /* color: #100045; */
    color:#fefeff;
    filter:invert(1);
    transform:scaleX(-1);
    margin-right: 6px;
    
}
.serviceimage{
    color:#100045;
    font-family: "Moderat-Bold";
    font-size: 16px;
    margin-bottom: 10px;
}

.loginContainer {
  flex-direction: column;
  flex: 1;
  display: flex;
  align-items: center;
  background-color: #e5e5e5;
}

.title {
  font-family: "Moderat-Bold";
}

.loginSection {
  width: 400px;
  background: #fff;
  margin: 16px 16px;
  padding: 16px 16px;
}

.loginSection button{
  height: 40px;
  width: 400px;
}
.loginSection button:disabled{
    opacity: 0.5;
    cursor: not-allowed;
  }

.loginSection input[type="Email"],
.loginSection input[type="password"],
.loginSection input[type="text"] {
    height: 40px;
    width: 400px;
    margin-bottom: 20px;
}
.loginSection input[type="checkbox"] {
  margin-bottom: 20px;
}

.logintitle{
  color: #100045;
  font-family: "Moderat Bold";
}
.errorMsg{
    font-size: 12px;
    position: relative;
    bottom: 17px;
    color: #e60000;
    font-family: "Moderat-Regular";
}
.loader{
  display: flex;
  justify-content: center;
}
.forgotPasswordLabel{
  font-family: "Moderat-Bold";
  color: #100045;
  cursor: pointer;
  margin-bottom: 16px;
}
.forgotPasswordEmail{
  width:400px;
  height:40px
}

body {
  background-color: #e5e5e5;
}

.separatorContainer {
  display: flex;
  flex: 1;
  /* background-color:aqua; */
  flex-direction: column;
}

.separator {
  height: 2px;
  background-color: #e5e5e5;
}

.or{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 30px;
  height: 30px;
  margin-top: -20px;
  align-self: center;
  color: #100045;
  font-family: "Moderat-Bold";
  font-size: 20px;
}

.vfLabel {
  font-family: "Moderat-Regular";
  color: #100045;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.addButton {
    display: flex;
    align-self: flex-end;
    justify-content: center;
    align-items: center;
    background-color: #28008C;
    height: 40px;
    width: 200px;
    border-radius: 20px;
    border-width: 0px;
    color: #fff;
    font-size: 12pt;
    font-family: Moderat-Regular;
    text-decoration-line: none;
}

.addButton:hover{

    background-color: grey;
}



.submit {
    display: flex;
    background-color: #100045;
    color: #fff;
    border-radius: 5px;
    height: 40px;
    border-width: 0px;
    width: 300px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14pt;
    font-family: "Moderat-Regular";
    cursor: pointer;
    margin: auto;
}

.linkButton {
    font-family: "Moderat-Bold";
    color: #100045;
    cursor: pointer;
    margin-bottom: 16px;
}

.buttonDestructive {
    display: flex;
    background-color: #CD3500;
    color: #fff;
    border-radius: 5px;
    border-width: 0px;
    align-self: center;
    justify-content: center;
    align-items: center;
    font-size: 14pt;
    font-family: "Moderat-Regular";
    cursor: pointer;
    margin-bottom: 20px;
    box-sizing: border-box;
  }
.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 40px;
    padding-bottom: 20px;
    max-width: 600px;
    padding-left: 50px;
    padding-right: 80px;
}
.ulContainer {
    margin-block-start: 0em;
    padding-inline-start: 16px;
  }
.value {
    font-family: "Moderat-Regular";
    margin-bottom: 10px;
    color: #717476;
    margin-left: 8px;
    text-transform: capitalize;
  } 
.answer {
    font-family: "Moderat-Regular";
    margin-bottom: 10px;
    margin-left: 2px;
  }
    
.other {
    color: gray;
    margin-left: 16px;
  }

.ul{
    padding-inline-start: 16px;
}

.other>*{
    display: inline-block;
}
.li{
    padding-inline-start: 0;
    text-transform: capitalize;
}




/* .back{
    margin-bottom: 0;
    text-decoration:none;
    position:absolute;
    right:0;
    top:0;
    margin-top: 0;
    width:auto !important;
    padding: 0 12px;
    background-color: white;
    color:#100045;
    border: 1px solid #100045;
} */

.backButton{
    width:16px;
    height: 16px;
    /* background-color: white; */
    color: #100045;
    border:1px;
    filter:invert(1);
    transform:scaleX(-1);
    margin-right: 6px;
}

.submit {
    display: flex;
    background-color: #100045;
    color: #fff;
    border-radius: 5px;
    height: 40px;
    border-width: 0px;
    width: 90px;
    align-self: center;
    justify-content: center;
    align-items: center;
    font-size: 14pt;
    font-family: "Moderat-Regular";
    cursor: pointer;
    text-decoration: none;
    align-self: self-end;
}






.pageTitle {
    color: #100045;
    font-family: "Moderat-Bold";
}
.fieldContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    /*border-bottom: 2px solid #E5E5E5;*/
}
.secondaryLabel{
    /* color: #5D30A6; */
    color: gray;
    font-family: Moderat-Regular;
    margin-bottom: 10px;
    /* margin-right: 10px; */
}

.KeyValuePairField{
    margin-left: 16px;
    display: flex;
}

.pgaeTitleContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.btnContainer{
    padding-left: 120px;
    display: flex;
    flex-direction: row;
}

.btnContainer button{
    width: 180px;
    height: 40px;
    margin-right: 10px;
    align-self: start;
}

.checkboxLabel{
    font-family: "Moderat-Bold";
    margin-top: 10px;
    color: #100045;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: auto;
}
.reviewCheckBox{
    margin-right: 16px;
    width: 20px;
    height: 20px;
}

.notOtherExtra {
    /* list-style: none;  // added if extra question bullet points need to remove  */ 
    /* margin-inline-start: -16px; */
    color: grey;
    margin-left: 16px;
}
.body{
    margin-top: 0.25rem;
    border: 1px solid #28008c30;
    margin-left: 0.5rem;
    padding: 5px;
    margin-left: 20px;
    border-radius:5px;
    padding: 10px
}
.dropZone {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #2e8b57;
  color: #2e8b57;
  background-color: #d7fbef;
  border-radius: 8px;
}
.dropZone:hover{
    cursor: pointer;
}
.error{
    color:red;
}
.cropper{
    border-radius: 4px;
    max-width: 600px;
    position: relative;
}
/* .button{
    display: flex;
    align-self: flex-end;
    justify-content: center;
    align-items: center;
    background-color: #28008C;
    height: 40px;
    width: 200px;
    border-radius: 20px;
    border-width: 0px;
    color: #fff;
    font-size: 12pt;
    font-family: Moderat-Regular;
    text-decoration-line: none;
} */
.button {
    display: flex;
    /* background-color: #100045; */
    background-color: #2ced0a;
    color: #fff;
    border-radius: 5px;
    height: 40px;
    border-width: 0px;
    width: 100px;
    align-self: center;
    justify-content: center;
    align-items: center;
    /* margin: 40px 20px; */
    font-size: 14pt;
    font-family: "Moderat-Regular";
    cursor: pointer;
    /* margin-left: 300px; */
    /* margin-left:20px; */
    margin-left: 10px;
    margin-right:10px;
    
}

.button1 {
    display: flex;
    background-color: rgba(255, 0, 0, 0.721);
    color: #fff;
    border-radius: 5px;
    height: 40px;
    border-width: 0px;
    width: 100px;
    align-self: center;
    justify-content: center;
    align-items: center;
    /* margin: 40px 20px; */
    font-size: 14pt;
    font-family: "Moderat-Regular";
    cursor: pointer;
    /* margin-left: 300px; */
    /* text-align: right; */

    
    /* margin-left: 187px; */
    
}

/* .button1:hover{
    background-color: grey;
}

.button:hover{
    background-color: grey;
} */

.buttonhead{
    display: flex;
    /* flex: .4; */
    /* justify-content: space-between; */
    flex-direction: row;
    margin-top: 20px;
    gap:10px;
    width:100%;
    justify-content:flex-end;

   
    /* justify-content: flex-start; */
    /* background-color: #ed0acb; */
}

.buttonhead2{
    flex: 1;
    justify-content: flex-end;
    flex-direction: row;
    
}

    .button2 {
        display: flex;
        background-color:  #100045;
        color: #fff;
        border-radius: 5px;
        height: 40px;
        border-width: 0px;
        width: 150px;
        align-self: center;
        justify-content: center;
        align-items: center;
        /* margin: 40px 20px; */
        font-size: 14pt;
        font-family: "Moderat-Regular";
        cursor: pointer;
        /* margin-left: 300px; */
    }

    .cropbutton{
        flex:1;
        flex-direction: column;
        align-items: center;
    }

    .noteContainer {
        margin-top: 15px;
        color: #717476;
        /* font-style: italic; */
        margin-bottom: 15px;
    }
    
    .noteContainer1{
        margin-top: 15px;
        color: #717476;
        font-style: italic;
        margin-bottom: 25px;;
    }

    .span{
        color: red;
    }
    .note{
        color:rgb(103, 102, 102);
        font-family: "Moderat-Bold";
        
    }
    
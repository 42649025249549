.container {
    /* background-color: slateblue; */
    /* display: flex; */
}

.tablist {
    display: flex;
}

.tabpanels {
    /* background-color: saddlebrown; */
}

.tabpanel {
    /* background-color: palevioletred; */
}

.tabContainer {
    /* background-color: seagreen; */
    height: 50px;
    padding-right: 30px;
    margin: 4px;
    align-items: center;
    color: #A0A1A4
}

.tabSelected {
    color: #131519;
    /* border-color: transparent;
    border-bottom-color: #131519;
    border-bottom-width: 2px;
    border-style: solid;
    border-radius: 3px; */
}

.tabContentContainer {
    /* background-color: yellowgreen; */
    height: 20px;
    flex: 1;
}
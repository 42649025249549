.section {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.sectionTitle {
    color: #100045;
    font-family: "Moderat Bold";
}

.separator {
    display: flex;
    height: 2px;
    background-color: #E5E5E5;
    align-self: stretch;
    margin-top: 40px;
}

.fieldContainer{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 40px;
    padding-bottom: 20px;
    max-width: 600px;
    padding-left: 50px;
    padding-right: 80px;
}

.container button {
    width: 120px;
    height: 40px;
}

.pageTitle {
    color: #100045;
    font-family: "Moderat-Bold";
}

.fieldContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    /*border-bottom: 2px solid #E5E5E5;*/
}

.KeyValuePairField{
    margin-right: 16px;
    display: flex;
}

.secondaryLabel{
    color: #717476;
    font-family: Moderat-Regular;
    margin-bottom: 10px;
    margin-right: 10px;
}

.backButton{
    width:16px;
    height: 16px;
    /* background-color: white; */
    color: #100045;
    border:1px;
    filter:invert(1);
    transform:scaleX(-1);
    margin-right: 6px;
}

.submit {
    display: flex;
    background-color: #100045;
    color: #fff;
    border-radius: 5px;
    height: 40px;
    border-width: 0px;
    width: 90px;
    align-self: center;
    justify-content: center;
    align-items: center;
    font-size: 14pt;
    font-family: "Moderat-Regular";
    cursor: pointer;
    text-decoration: none;
    align-self: self-end;
}
.body{
    margin-top: 0.25rem;
    border: 1px solid #28008c30;
    margin-left: 0.5rem;
    padding: 5px;
    margin-left: 20px;
    border-radius:5px;
    padding: 10px
}
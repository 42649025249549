.feedInfo {
    display: flex;
    flex-direction: column;
}

.itemTitle {
    color: #100045;
    font-family: "Moderat-Bold";
    font-size: 18px;
}

.itemInfo {
    color: #717476;
    font-family: "Moderat-Regular";
    font-size: 16px;
    margin-top: 10px;
}

.separator {
    background-color: #A0A1A4;
    height: 2px;
    /* margin-right: 0px; */
    margin-top: 10px;
    margin-bottom: 10px;
}

.tab {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:white;
    border: solid 1px;
    border-color: #100045;
    font-size: 20px;
    border-radius: 5px;
    height: 30px;
    text-align: center;
    padding: 5px;
    color: #100045;
    min-width: 150px;
    cursor: pointer;
}

.selectedTab {
    background-color:#100045;
    color: #ffffff
}
.button{
    display: flex;
    align-self: flex-end;
    justify-content: center;
    align-items: center;
    /* background-color: #28008C; */
    height: 40px;
    width: 100px;
    border-radius: 20px;
    border-width: 0px;
    color: #fff;
    font-size: 12pt;
    font-family: Moderat-Regular;
    text-decoration-line: none;
    margin-bottom: 15px;
    margin-right: 5px;
    cursor: pointer;
    margin-top: 18px;
    
}
.publish{
    background-color: #28008C;

}
.unpublish{
    background-color: #d31717;
}
.total{
    width:fit-content;
    /* background-color: #100045; */
    /* border: 3px solid #100045 !important; */
    color: #100045;
    font-family: Moderat-Regular;
    border-radius: 5px;
    border-width: 0;
    padding: 5px;
    font-size: 18px;
    text-decoration-line: none;
    font-weight: bold;
    margin-left:05px;
    align-self: center;
}

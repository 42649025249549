.listContainer {
    display: flex;
    flex: 1;
    /* background-color: brown; */
    align-self: stretch;
    /* padding: 10px; */
    margin-top: 4px;
}

.serviceList {
    display: flex;
    /* background-color: green; */
    flex: 1;
    flex-direction: column;
    list-style: none;
    justify-content: center;
}

.pending {
    color: #CD3500
}

.title {
    /* color: #2d2e2f; */
    color: #100045;
    font-family: Moderat-Bold;
    font-size: 14pt;
    
}

.subtitle {
    color: #717476;
    font-family: Moderat-Regular;
    margin: 10px 0px 10px 0px;
    /* font-size: 16pt; */
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   line-height: 20px;
   max-height: 40px;      /* fallback */
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
}

.type {
    display: flex;
    align-items: center;
    color: #5D30A6;
    font-family: Moderat-Regular;
    font-size: 14pt;
    /* background-color: red; */
    
}
.typeIcon {
    height: 20px;
    margin-right: 5px;
    /* background-color: navy; */
}

.total{
    width:fit-content;
    /* background-color: #100045; */
    /* border: 3px solid #100045 !important; */
    color: #100045;
    font-family: Moderat-Regular;
    border-radius: 5px;
    border-width: 0;
    padding: 5px;
    margin-left: 40px;
    font-size: 18px;
    text-decoration-line: none;
    font-weight: bold;
    align-self: center;
    
}
.divFlex{
    display:flex;
    justify-content: space-between;
    }
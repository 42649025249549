.chevronContainer {
    display: flex;
    align-self: center;
    cursor: pointer;
}

.chevron {
    /* background-color: mediumslateblue; */
    height: 30px;
    width: 30px;
}

.publish {
    display: flex;
    background-color: #28008C; /* #009900;  #CD3500*/
    height: 40px;
    width: 100px;
    color: #fff;
    font-family: Moderat-Regular;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
}

.delete {
    display: flex;
    /* background-color:#E60000; */
    height: 50px;
    width: 50px;
    /* color: #fff;
    font-weight: 900; */
    border-radius: 25px;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
}

.edit {
    display: flex;
    /* background-color: #A0A1A4;    ; */
    height: 50px;
    width: 50px;
    /* color: #fff;
    font-weight: 900; */
    border-radius: 25px;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
}



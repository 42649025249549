.ampmHandle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20pt;
   
}

.ampmValue {
    /* background-color: aquamarine; */
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 40px;
    font-family: "Moderat-Bold";
    color: #fff;
}

.label{
    font-family: "Moderat-Bold";
    margin-bottom: 10px;
    color: #100045; 
}


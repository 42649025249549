.container {
    display: flex;
    flex-direction: column;
    /* background-color: salmon; */
    flex: 1;
    padding-top: 40px;
    padding-left: 120px;
    padding-right: 80px;
    max-width: 600px;
    position: relative;
}

.pageTitle {
    color: #100045;
    font-family: "Moderat-Bold";
}

.form {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.vContainer{
    display: flex;
    flex-direction: column;
}

.hContainer {
    display: flex;
    flex-direction: row;
}

.label {
    font-family: "Moderat-Bold";
    margin-bottom: 10px;
    color: #100045;
}

.textField {
    background-color: #efefef;
    height: 40px;
    max-width: 600px;
    border-radius: 5px;
    border-width: 1px;
    border-color: #C5C1C8;
    border-style: solid;
    padding-left: 10px;
    margin-bottom: 20px;
    color: #100045;
    font-family: "Moderat-Regular";
}

.textArea {
    background-color: #efefef;
    min-height: 120px;
    max-width: 600px;
    min-width: 500px;
    border-radius: 5px;
    border-width: 1px;
    border-color: #C5C1C8;
    border-style: solid;
    padding-left: 10px;
    padding-top: 10px;
    margin-bottom: 20px;
    font-family: "Moderat-Regular";
    color: #100045;
}

.submit {
    display: flex;
    background-color: #100045;
    color: #fff;
    border-radius: 5px;
    height: 40px;
    border-width: 0px;
    width: 300px;
    align-self: center;
    justify-content: center;
    align-items: center;
    margin: 40px 20px;
    font-size: 14pt;
    font-family: "Moderat-Regular";
    cursor: pointer;
}

.submit:disabled{
    background-color: gray;
}


/* .back{
    margin-bottom: 0;
    text-decoration:none;
    position:absolute;
    right:0;
    top:0;
    margin-top: 0;
    width:auto !important;
    padding: 0 12px;
    background-color: white;
    color:#100045;
    border: 1px solid #100045;
} */
.backButton{
    width:16px;
    height: 16px;
    /* background-color: white; */
    color: #100045;
    border:1px;
    filter:invert(1);
    transform:scaleX(-1);
    margin-right: 6px;
}

.submitBack {
    display: flex;
    background-color: #100045;
    color: #fff;
    border-radius: 5px;
    height: 40px;
    border-width: 0px;
    width: 90px;
    align-self: center;
    justify-content: center;
    align-items: center;
    font-size: 14pt;
    font-family: "Moderat-Regular";
    cursor: pointer;
    text-decoration: none;
    align-self: self-end;
}

.dateInputButton {
    display: flex;
    align-items: center;
    background-color: #efefef;
    height: 42px;
    width: 160px;
    border-radius: 5px;
    border-width: 1px;
    border-color: #C5C1C8;
    border-style: solid;
    color: #100045;
    margin-right:40px;
    margin-bottom: 20px;
}
.dateInputButton:hover{
    cursor: pointer;
}
.calendarIcon {
    height: 26px;
    width: 26px;
    margin-right: 10px;
}

.timeField {
    display: flex;
    align-items: center;
    background-color: #efefef;
    height: 34px;
    border-radius: 5px;
    border-width: 1px;
    border-color: #C5C1C8;
    border-style: solid;
    margin-right: 20px;
    padding: 4px 10px;
    color: #100045;
    
}

.timeInput {
    background-color: #efefef;
    height: 34px;
    border-width: 0px;
    width: 100px;  
    color: #100045; 
}

.timeIcon {
    height: 26px;
    width: 26px;
    margin-right: 10px;
}

.locationType {
    /* background-color: red; */
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 20px;
    font-family: "Moderat-Light";
    margin-bottom: 20px;
    color: #100045;
}

.locTypeSwitch {
    margin-left: 20px;
}

.option {
    display: flex;
    background-color: aquamarine;

}

.row{
    display: flex;
    align-items: center; 
    margin-bottom: 20px;
}
.apSwitch {
    margin-right: 30px;
}

.errorSpan {
    color: red;
    margin-top: -15px;
    margin-bottom: 10px;
}


.actionContainer {
    display: flex;
    /* width: 290px; */
    flex:1;
    /* background-color: navajowhite; */
    justify-content: flex-end;
    align-items: center;
    /* background-color: red; */
}

.detailsContainer {
    /* background-color: salmon; */
    display: flex;
    flex-direction: column;
    flex:1;
    margin-left: 30px;
    margin-right: 10px;
    align-self: stretch;
    justify-content: space-between; 
    /* background-image: linear-gradient(to right, rgb(161, 194, 228), rgba(255,0,0,0)); */
    border-radius: 5px;
    padding:5px;
    min-width: 250px;
    
}

.statusContainer {
    /* background-color: salmon; */
    display: flex;
    flex-direction: column;
    width: 120px;
    margin-left: 10px;
    margin-right: 10px;
    align-self: stretch;
    /* margin-top: 17px; */
    margin-left: 10px;
}

.statusTitle {
    color: #717476;
    font-family: Moderat-Regular;
    margin-bottom: 10px;
}

.statusValue {
    color: #009900;
    font-family: Moderat-Regular;
    text-transform: capitalize;
}

.itemContainer{
    display: flex;
    /* background-color: aquamarine; */
    min-height: 80px;
    flex-direction: row;
    margin-bottom: 20px;
    border-radius: 7px;
    border-color: #28008C30;
    border-width: 2 px;
    border-style: solid;
    padding: 8px;
    align-items: center;
    align-self: stretch;
}